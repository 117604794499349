<template>
  <v-sheet class="mx-auto pa-3" color="#F6F7F8" max-width="375">
    <template v-for="budget_category in budget_categories"> 
      <budget-category-card
        :key="budget_category.id"
        :limit="budget_category.planned_amount"
        :spent="budget_category.spent_amount"
        :categoryName="budget_category.category.display_name"
        :categoryIconName="budget_category.category.icon ? budget_category.category.icon.name : null"
        :categoryColor="budget_category.category.icon ? budget_category.category.icon.color : null"
        class="mt-3"
      >
      </budget-category-card>
    </template>
  </v-sheet>
</template>

<script>
import BudgetCategoryCard from "../components/BudgetCategoryCard.vue";

export default {
  name: "BudgetView",

  components: {
    BudgetCategoryCard,
  },

  data: () => ({
    selected_budget: "",
  }),
  watch: {
    selected_budget(new_selected_budget) {
      this.fetchBudgetCategories(new_selected_budget.id);
    },
  },
  methods: {
    fetchBudgets() {
      this.$store.dispatch("budgets/fetchBudgets").then((budgets) => {
        this.selected_budget = budgets[0];
      });
    },
    fetchBudgetCategories(budget_id) {
      return this.$store.dispatch("budgets/fetchBudgetCategories", budget_id);
    },
  },
  computed: {
    budgets() {
      return this.$store.getters["budgets/getBudgets"];
    },
    budget_categories() {
      return this.$store.getters["budgets/getBudgetCategories"];
    },
  },
  created() {
    this.fetchBudgets();
  },
};
</script>
