<template>
    <v-form @submit.prevent="submit">
        <v-card>
        <v-card-text>
            <v-select
            v-model="selected_category"
            :items="categories"
            item-text="display_name"
            item-value="id"
            label="Категорія"
            return-object
            single-line
            ></v-select>

            <v-text-field v-model="transaction_description" label="Опис"/>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancel">Відміна</v-btn>
            <v-btn color="primary" type="submit">Зберегти</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
  export default {
    data: () => ({
      selected_category: '',
      transaction_description: '',
      planned_transaction: '',
    }),
    methods:{
        cancel() {
            this.$router.push('/transactions');
        },
        submit () {
            const payload = {
                'category': this.selected_category.id,
                'description': this.transaction_description,
                'planned_transaction': this.planned_transaction,
            }
            this.$store.dispatch('transactions/updateTransaction', {'id': this.$route.params.id, 'data': payload}).then(() => {
                this.$router.push('/transactions');
            });
        },
        fetchTransaction(id) {
            this.$store.dispatch('transactions/fetchTransaction', id).then((transaction) => {
                this.selected_category = transaction.category;
                this.transaction_description = transaction.description;
                this.planned_transaction = transaction.planned_transaction;
            });
        },
        fetchCategories() {
            this.$store.dispatch('categories/fetchCategories');
        },
    },
    computed: {
      categories() {
        return this.$store.getters['categories/getCategories'];
      }
    },
    created() {
        this.fetchCategories();
        const id = this.$route.params.id;
        if (id) {
            this.fetchTransaction(id);
        }
    }
  }
</script>
