import axios from 'axios';
const API_URL = 'https://api.kul-lab.cyou/api/';

class TransactionsService {
  fetchTransactions(params) {
    return axios
      .get(API_URL + 'transactions/', {params})
      .then(response => {
        return response.data;
      });
  }
  fetchTransaction(id) {
    return axios
      .get(API_URL + 'transactions/' + id + '/')
      .then(response => {
        return response.data;
      });
  }
  updateTransaction(id, data) {
    return axios
      .patch(API_URL + 'transactions/' + id + '/', data)
      .then(response => {
        return response.data;
      });
  }
}
export default new TransactionsService();
