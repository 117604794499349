<template>
<div>
    <v-app-bar
        app
        color="primary"
        dark
    >
        <v-app-bar-nav-icon class="hidden-sm-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-tabs
          v-if="isLoggedIn"
          centered
          class="ml-n9 hidden-sm-and-down"
          color="white darken-1"
        >
          <v-tab
            v-for="link in links"
            :key="link.text"
            :to="link.route"
          >
            {{ link.text }}
          </v-tab>
        </v-tabs>
        
    </v-app-bar>

    <v-navigation-drawer temporary absolute app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Application
          </v-list-item-title>
          <v-list-item-subtitle>
            subtext
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

</div>
</template>
<script>
  export default {
    name: 'AppBar',

    data: () => ({
      drawer: false,
      links: [
        { icon: 'mdi-home', text: 'Бюджет', route: '/budget' },
        { icon: 'mdi-sync', text: 'Коригування', route: '/budget-categories-transfer' },
        { icon: 'mdi-format-list-bulleted', text: 'Транзакції', route: '/transactions' },
      ],
    }),
    computed: {
      isLoggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },
  }
</script>