import CategoriesService from '../services/categories.service';

export const categories = {
  namespaced: true,
  state: {
    categories: []
  },
  actions: {
    fetchCategories({ commit, getters }) {
        let categories = getters.getCategories;

        if (categories.length) {
            return;
        }

        return CategoriesService.fetchCategories().then(
            categories => {
                commit('setCategories', categories);
                return Promise.resolve(categories);
            }
        );
    }
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
  },
  getters: {
    getCategories(state) {
        return state.categories;
    }
}
};
