import BudgetsService from '../services/budgets.service';

export const budgets = {
  namespaced: true,
  state: {
    budgets: [],
    budget_categories: [],
  },
  actions: {
    fetchBudgets({ commit, getters }) {
        let budgets = getters.getBudgets;

        if (budgets.length) {
            return budgets;
        }

        return BudgetsService.fetchBudgets().then(
            budgets => {
                commit('setBudgets', budgets);
                return Promise.resolve(budgets);
            }
        );
    },
    fetchBudgetCategories({ commit }, id) {
        return BudgetsService.fetchBudgetCategories(id).then(
            budget_categories => {
                commit('setBudgetCategories', budget_categories);
                return Promise.resolve(budget_categories);
            }
        );
    },
    transferAmountBetweenBudgetCategories(_, payload) {
        return BudgetsService.transferAmountBetweenBudgetCategories(payload);
    }
  },
  mutations: {
    setBudgets(state, budgets) {
      state.budgets = budgets;
    },
    setBudgetCategories(state, budget_categories) {
        state.budget_categories = budget_categories;
    },
  },
  getters: {
    getBudgets(state) {
        return state.budgets;
    },
    getBudgetCategories(state) {
        return state.budget_categories;
    },
    getBudgetCategoryWithHightestLeftAmount(state) {
        return state.budget_categories.reduce((prev, curr) => {
            return prev.left_amount > curr.left_amount ? prev : curr;
        });
    },
    getBudgetCategoryWithLowestLeftAmount(state) {
        return state.budget_categories.reduce((prev, curr) => {
            return prev.left_amount < curr.left_amount ? prev : curr;
        });
    }
  }
};
