import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TransactionsView from '../views/TransactionsView.vue'
import TransactionView from '../views/TransactionView.vue'
import LoginView from '../views/LoginView.vue'
import BudgetView from '../views/BudgetView.vue'
import BudgetTransferFundsView from '../views/BudgetTransferFundsView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: TransactionsView
  },
  {
    path: '/transactions/:id',
    name: 'transaction',
    props: true,
    component: TransactionView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/budget',
    name: 'budget',
    component: BudgetView
  },
  {
    path: '/budget-categories-transfer',
    name: 'budget-categories-transfer',
    component: BudgetTransferFundsView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user-token');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
