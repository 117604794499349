<template>
      <v-form @submit.prevent="submit">
         <v-card>
            <v-card-text>
               <v-text-field v-model="username" label="Username"/>
               <v-text-field v-model="password" label="Password" type="password" />
            </v-card-text>
            <v-card-actions>
               <v-btn type="submit">
                  Login
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-form>
</template>
<script>
  export default {
    data: () => ({
        'username': '',
        'password': ''
    }),
    methods: {
      submit () {
        this.$store.dispatch('auth/login', {'username': this.username, 'password': this.password}).then(() => {
            this.$router.push('/budget');
        });
      }
    },
  }
</script>