import TransactionsService from '../services/transactions.service';

export const transactions = {
  namespaced: true,
  state: {
    transactions: []
  },
  actions: {
    fetchTransactions({ commit }, {datetime_after, datetime_before}) {
        const params = {'datetime_after': datetime_after, 'datetime_before': datetime_before};

        return TransactionsService.fetchTransactions(params).then(
            transactions => {
                commit('setTransactions', transactions);
                return Promise.resolve(transactions);
            }
        );
    },
    fetchTransaction(_, id) {
        return TransactionsService.fetchTransaction(id).then(
            transaction => {
                return Promise.resolve(transaction);
            }
        );
    },
    updateTransaction(_, {id, data}) {
        return TransactionsService.updateTransaction(id, data).then(
            transaction => {
                return Promise.resolve(transaction);
            }
        );
    }
  },
  mutations: {
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
  },
  getters: {
    getTransactions(state) {
        return state.transactions;
    }
}
};
