<template>
  <budget-transfer-funds-form/>
</template>

<script>
  import BudgetTransferFundsForm from '../components/BudgetTransferFundsForm'

  export default {
    name: 'BudgetTransferFundsView',

    components: {
      BudgetTransferFundsForm,
    },
  }
</script>
