<template>
    <v-form @submit.prevent="submit">
        <v-card>
        <v-card-text>
            <v-select
            v-model="selected_budget"
            :items="budgets"
            item-text="description"
            item-value="id"
            label="Бюджет"
            return-object
            single-line
            ></v-select>

            <v-select
            v-model="selected_from_category"
            :items="budget_categories"
            item-text="category.display_name"
            item-value="id"
            label="З категорії"
            return-object
            single-line
            ></v-select>

            <div>Наявно: {{left_from_category | numberify}}</div>

            <v-text-field
                v-model="transfer_amount"
                label="Сума"
                required
            ></v-text-field>

            <v-select
            v-model="selected_to_category"
            :items="budget_categories"
            item-text="category.display_name"
            item-value="id"
            label="У категорію"
            return-object
            single-line
            ></v-select>

            <div>Залишилося: {{left_to_category | numberify}}</div>

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancel">Відміна</v-btn>
            <v-btn color="primary" type="submit">Зберегти</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
  export default {
    data: () => ({
      selected_budget: '',
      selected_to_category: '',
      selected_from_category: '',
      transfer_amount: 0,
    }),
    watch: {
        selected_budget(new_selected_budget){
            this.fetchBudgetCategories(new_selected_budget.id).then(() => {
                this.selected_to_category = this.budgetCategoryWithLowestLeftAmount;
                this.selected_from_category = this.budgetCategoryWithHightestLeftAmount;
            });
        }
    },
    methods:{
        cancel() {
            this.$router.push('/');
        },
        submit () {
            const payload = {
                'from_category': this.selected_from_category.id,
                'to_category': this.selected_to_category.id,
                'amount': this.transfer_amount,
            }
            this.$store.dispatch('budgets/transferAmountBetweenBudgetCategories', payload).then(() => {
                this.$router.push('/');
            });
        },
        fetchBudgets() {
            this.$store.dispatch('budgets/fetchBudgets').then((budgets) => {
                this.selected_budget = budgets[0];
            });
        },
        fetchBudgetCategories(budget_id) {
            return this.$store.dispatch('budgets/fetchBudgetCategories', budget_id)
        },
    },
    computed: {
      budgets() {
        return this.$store.getters['budgets/getBudgets'];
      },
      budget_categories() {
        return this.$store.getters['budgets/getBudgetCategories'];
      },
      left_to_category() {
        let selected_category_letf = this.selected_to_category ? this.selected_to_category.left_amount: 0;
        return selected_category_letf + parseFloat(this.transfer_amount);
      },
      left_from_category() {
        let selected_from_category_left = this.selected_from_category ? this.selected_from_category.left_amount: 0;
        return selected_from_category_left - parseFloat(this.transfer_amount);
      },
      budgetCategoryWithLowestLeftAmount() {
        return this.$store.getters['budgets/getBudgetCategoryWithLowestLeftAmount'];
      },
      budgetCategoryWithHightestLeftAmount() {
        return this.$store.getters['budgets/getBudgetCategoryWithHightestLeftAmount'];
      },
    },
    created() {
        this.fetchBudgets();
    },
    filters: {
      numberify: function (value) {
          if (!value) return '0';
          return parseFloat(value).toFixed(2);
      }
    }
  }
</script>