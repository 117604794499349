<template>
<img
    :alt="categoryIconName"
    :src="`/img/categories/${categoryIcon}.svg`"
>
</template>

<script>
  export default {
    name: 'CategoryIcon',
    props: {
      categoryIconName: {
        type: String,
        required: false,
      },
    },
    computed: {
        categoryIcon() {
            return this.categoryIconName ? this.categoryIconName : 'other';
        },
    },
  }
</script>
