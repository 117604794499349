<template>
  <v-bottom-navigation
    :value="value"
    color="primary"
    app
    class="hidden-sm-and-up"
    v-if="isLoggedIn"
  >
    <v-btn
      v-for="link in links"
      :key="link.text"
      :to="link.route"
    >
      <span>{{ link.text }}</span>
      <v-icon>{{ link.icon }}</v-icon>
    </v-btn>

  </v-bottom-navigation>
</template>

<script>
  export default {
    name: 'BottomNavigation',

    data: () => ({
      links: [
        { icon: 'mdi-home', text: 'Бюджет', route: '/budget' },
        { icon: 'mdi-sync', text: 'Коригування', route: '/budget-categories-transfer' },
        { icon: 'mdi-format-list-bulleted', text: 'Транзакції', route: '/transactions' },
      ],
    }),
    computed: {
      isLoggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },
  }
</script>