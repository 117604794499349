<template>
  <v-card
    class="mx-auto"
    max-width="375"
    tile
  >
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
          icon
          :color="dates.length ? 'primary' : 'grey'"
          @click="show = !show"
        >
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
    </v-card-title>

    <v-expand-transition>
      <div v-show="show">
        <v-card-text>
          <v-date-picker
            v-model="dates"
            flat
            full-width
            range
            locale="uk"
            :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="resetDatesFilters"
            >
              Скинути
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="applyDatesFilters"
            >
              Показати
            </v-btn>
        </v-date-picker>

        </v-card-text>
      </div>
    </v-expand-transition>

    <v-list flat>
      <v-list-item-group v-model="model">
        <template v-for="transaction in transactions" >
        <v-list-item class="px-5" two-line :key="transaction.id" :to="{ name: 'transaction', params: { id: transaction.id }}"> 
          
          <v-list-item-avatar size="48">
            <category-icon :categoryIconName="transaction.category.icon ? transaction.category.icon.name : null"></category-icon>
          </v-list-item-avatar>

          <v-list-item-content class="flex-grow-1 flex-shrink-0">
            <v-list-item-title>{{ transaction.description }}</v-list-item-title>
            <v-list-item-subtitle>{{ transaction.category.display_name }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content style="max-width: 95px;" class="flex-shrink-1 text-right">
            <v-list-item-title> {{ transaction.amount | numberify}}</v-list-item-title>
            <v-list-item-subtitle>{{ transaction.datetime | prettifyDate }}</v-list-item-subtitle>
          </v-list-item-content>
          
        </v-list-item>
        <v-divider :key="`${transaction.id}-divider`" class="mx-5"></v-divider>
        </template>

      </v-list-item-group>
    </v-list>

  </v-card>
</template>
<script>
  import CategoryIcon from '@/components/CategoryIcon.vue';
  import moment from 'moment';
  import 'moment/locale/uk';
  moment.locale('uk');

  export default {
    components: {
      CategoryIcon,
    },
    data: () => ({
      dates: [],
      show: false,
      dateRangeText: '2022-09-10 ~ 2022-09-20',
      model: 0,
      moment: '',
      startDate: '',
      endDate: '',
    }),
    methods:{
        fetchTransactions() {
            this.$store.dispatch('transactions/fetchTransactions', {'datetime_after': this.startDate.format(), 'datetime_before': this.endDate.format()})
        },
        applyDatesFilters() {
            this.show = false;
            this.startDate = moment(this.dates[0]);
            this.endDate = moment(this.dates[1]);
            this.fetchTransactions();
        },
        resetDatesFilters() {
            this.show = false;
            this.dates = [];
            this.startDate = moment(this.month).startOf('month');
            this.endDate = moment(this.month).endOf('month');
            this.fetchTransactions();
        },

    },
    computed: {
      transactions() {
        return this.$store.getters['transactions/getTransactions'];
      }
    },
    created() {
      this.moment = moment();

      this.startDate = moment(this.month).startOf('month');
      this.endDate = moment(this.month).endOf('month');

      this.fetchTransactions();
    },
    filters: {
      numberify: function (value) {
          if (!value) return '0'
          return new Intl.NumberFormat().format(Math.trunc(value))
      },
      prettifyDate: function (value) {
          return moment(value).format('MMM D HH:mm')
      },
    }
  }
</script>