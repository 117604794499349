import axios from 'axios';
const API_URL = 'https://api.kul-lab.cyou/api/';

class BudgetsService {
  fetchBudgets(params) {
    return axios
      .get(API_URL + 'budget/', {params})
      .then(response => {
        return response.data;
      });
  }
  fetchBudget(id) {
    return axios
      .get(API_URL + 'budget/' + id + '/')
      .then(response => {
        return response.data;
      });
  }
  fetchBudgetCategories(id) {
    return axios
      .get(API_URL + 'budget/' + id + '/' + 'categories/')
      .then(response => {
        return response.data;
      });
  }
  transferAmountBetweenBudgetCategories(data) {
    return axios
      .post(API_URL + 'budget/transfer/', data)
      .then(response => {
        return response.data;
      });
  }
}
export default new BudgetsService();
