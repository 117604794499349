<template>
<v-card
    max-width="375"
    class="px-5 py-3 budget-card"
>
    <v-list-item class="px-0"> 
        <v-list-item-avatar size="48">
            <category-icon :categoryIconName="categoryIconName"></category-icon>
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title>{{categoryName}}</v-list-item-title>
            <v-list-item-subtitle>₴{{perDay | numberify}}/день</v-list-item-subtitle>
        </v-list-item-content>
        
        <v-list-item-action>
            <v-btn fab small elevation="0" color="#E8EBEE">
                <v-icon color="#5C616F">mdi-chevron-right</v-icon>
            </v-btn>
        </v-list-item-action>

    </v-list-item>
    <v-divider class="my-2"></v-divider>

    <div class="d-flex justify-space-between py-2">
        <div style="width: 85px">
            <p class="ma-0 subheader">Витрачено</p>
            <p class="mt-1 ma-0 spent-value" :style="{color: categoryColorOrDefault}">₴{{spent | numberify}}</p>
        </div>
        <div style="width: 85px">
            <p class="ma-0 subheader">Залишилося</p>
            <p class="mt-1 ma-0 left-value" :style="{color: categoryColorOrDefault}">₴{{leftToSpend | numberify}}</p>
        </div>
        <div style="width: 85px">
            <p class="ma-0 subheader">Ліміт</p>
            <p class="mt-1 ma-0 limit-value">₴{{limit | numberify}}</p>
        </div>
    </div>

    <v-progress-linear
        :color="categoryColorOrDefault"
        height="16"
        :value="spendPercent"
        class="my-2 rounded-lg"
    ></v-progress-linear>

</v-card>
</template>

<script>
  import CategoryIcon from './CategoryIcon.vue'

  export default {
    name: 'BudgetCategoryCard',
    components: {
      CategoryIcon
    },
    props: {
      categoryName: {
        type: String,
        required: true,
      },
      categoryIconName: {
        type: String,
        required: false,
      },
      categoryColor: {
        type: String,
        required: false,
      },
      spent: {
        type: Number,
        required: true,
      },
      limit: {
        type: Number,
        required: true,
      },
    },
    computed: {
      leftToSpend: function() {
        if (this.limit === 0) {
          return 0;
        }
        return this.limit - this.spent;
      },
      spendPercent: function() {
        if (this.limit === 0) {
          return 0;
        }
        return (this.spent / this.limit) * 100;
      },
      perDay: function() {
        if (this.limit === 0) {
          return 0;
        }
        return this.limit / 30;
      },
      categoryColorOrDefault: function() {
        if (this.categoryColor) {
          return this.categoryColor;
        }
        return '#7D8198';
      },
    },
    filters: {
      numberify: function (value) {
          if (!value) return '0'
          return new Intl.NumberFormat().format(Math.trunc(value))
      }
    }
  }
</script>

<style scoped>
  .budget-card {
    border-radius: 16px;
    box-shadow: 0px 16px 64px -32px rgba(0, 0, 0, 0.24) !important;
  }
  .subheader {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.12px;
    color: #5C616F;
  }
  .spent-value {
    /* Text Large / SemiBold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;

    /* identical to box height, or 141% */
    letter-spacing: -0.4px;

    /* Chart Color / 1 */
    color: #453ABD;
  }

  .left-value {
    /* Text Large / SemiBold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;

    /* identical to box height, or 141% */
    letter-spacing: -0.4px;

    /* Chart Color / 1 */
    color: #453ABD;

    opacity: 0.56;
  }

  .limit-value {
    /* Text Large / SemiBold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;

    /* identical to box height, or 141% */
    letter-spacing: -0.4px;

    color: #040C22;
  }
  /* .v-progress-linear {
    border-radius: 10px;
  } */
</style>