import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.module';
import { transactions } from './transactions.module';
import { categories } from './categories.module';
import { budgets } from './budgets.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    transactions,
    categories,
    budgets
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
})
