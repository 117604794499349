import axios from 'axios';
const API_URL = 'https://api.kul-lab.cyou/api/';

class CategoriesService {
  fetchCategories(params) {
    return axios
      .get(API_URL + 'categories/', {params})
      .then(response => {
        return response.data;
      });
  }
}
export default new CategoriesService();
