import axios from 'axios';
const API_URL = 'https://api.kul-lab.cyou/api/';

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + 'auth-token/', {
        username: username,
        password: password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user-token', response.data.token);
        }
        return response.data.token;
      });
  }
  logout() {
    localStorage.removeItem('user-token');
  }
}
export default new AuthService();
